/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { graphql } from 'gatsby';

import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import ClientItem from '../components/ClientItem';

import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(110)};
`;

const Intro = styled(Col)`
  text-align: center;
  margin: ${rem(50)} auto ${rem(75)};
  width: 100%;
  max-width: ${rem(500)};
  /* max-width: ${(props) => props.theme.grid.maxWidthText}; */
  font-size: ${rem(18)};

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(680)};
    font-size: ${rem(20)};
  }

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
  }
`;

const Section = styled(Row)`
  justify-content: center;
  margin-bottom: ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const GridItem = styled(Col)`
  width: 100%;
  margin-bottom: ${rem(42)};

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }

  ${breakpoint('large')} {
    width: 33.33%;
  }
`;

const ColumnsTitle = styled.h2`
  display: block;
  width: 100%;
  text-align: center;
  font-size: ${rem(24)};
  margin-bottom: ${rem(30)};

  ${breakpoint('large')} {
    font-size: ${rem(28)};
    margin-bottom: ${rem(35)};
  }
`;

function App({ data }) {
  const { allClients, clients: page } = data.prismic;

  const typeMap = {
    Coach: 'coaches',
    Player: 'players',
  };

  const items = allClients.edges
    .sort((a, b) => {
      const x = a.node.title[0].text.toLowerCase();
      const y = b.node.title[0].text.toLowerCase();

      // eslint-disable-next-line no-nested-ternary
      return x < y ? -1 : x > y ? 1 : 0;
    })
    .reduce(
      (clientTypes, { node: client }) => ({
        ...clientTypes,
        [typeMap[client.category]]: [
          ...(clientTypes[typeMap[client.category]] || []),
          client,
        ],
      }),
      {},
    );

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      <Hero heading={RichText.asText(page.title)} image={page.hero_imageSharp}>
        {page.hero_text && <RichText render={page.hero_text} />}
      </Hero>

      {page.content && page.content.length && RichText.asText(page.content) && (
        <Row>
          <Intro>
            <RichText render={page.content} />
          </Intro>
        </Row>
      )}

      <Container>
        {items.players && items.players.length && (
          <div>
            <Row>
              <Col>
                <ColumnsTitle>Our players</ColumnsTitle>
              </Col>
            </Row>
            <Section>
              {items.players.map((client) => (
                <GridItem key={client._meta.id}>
                  <ClientItem data={client} />
                </GridItem>
              ))}
            </Section>
          </div>
        )}
        {items.coaches && items.coaches.length && (
          <div>
            <Row>
              <Col>
                <ColumnsTitle>Our coaches</ColumnsTitle>
              </Col>
            </Row>
            <Section>
              {items.coaches.map((client) => (
                <GridItem key={client._meta.id}>
                  <ClientItem data={client} />
                </GridItem>
              ))}
            </Section>
          </div>
        )}
      </Container>
    </Layout>
  );
}

App.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query ClientsQuery {
    prismic {
      clients(lang: "en-gb", uid: "family") {
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_text
        seo_description
        seo_meta_title
        title
        content
        _meta {
          uid
          type
          lang
          id
        }
        _linkType
      }
      allClients(first: 500) {
        totalCount
        edges {
          node {
            _meta {
              uid
              type
              tags
              lastPublicationDate
              lang
              id
              firstPublicationDate
              alternateLanguages {
                id
                lang
                type
                uid
              }
            }
            _linkType
            category
            title
            seo_meta_title
            seo_description
            position
            hero_text
            foot
            dob
            contract_end
            content
            club
            client_image
            client_imageSharp {
              childImageSharp {
                fluid(maxWidth: 900, maxHeight: 720, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default App;
