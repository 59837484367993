import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import styled from 'styled-components';

import { RichText } from 'prismic-reactjs';
import Image from '../Image';
import Link from '../Link';

import { breakpoint } from '../../theme';

const Root = styled.div`
  width: 100%;
`;

const Inner = styled(Link)`
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.grey};
  text-align: left;
`;

const Thumbnail = styled(Image)`
  display: block;
  padding: ${(props) => props.theme.spacing.gutter};
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.black};
`;

const Content = styled.div`
  display: block;
  padding: ${(props) => props.theme.spacing.gutter};
  width: 100%;
  color: ${(props) => props.theme.palette.colors.white};
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(22)};

  ${breakpoint('large')} {
    font-size: ${rem(26)};
  }
`;

const Tags = styled.h4`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(16)};

  ${breakpoint('large')} {
    font-size: ${rem(17)};
  }
`;

function ClientItem({ data }) {
  return (
    <Root>
      <Inner to={data}>
        {data.client_image && (
          <Thumbnail
            fluid={data.client_imageSharp?.childImageSharp?.fluid}
            fallback={data.client_image}
            alt={RichText.asText(data.title)}
          />
        )}

        <Content>
          <Title>{RichText.asText(data.title)}</Title>
          <Tags>{data.position ? data.position : data.category}</Tags>
        </Content>
      </Inner>
    </Root>
  );
}

ClientItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ClientItem;
